<template>
	<div class="grid">
		<div class="md:col-4 col-12">
			<Card>
				<!-- <template #title>
					{{title}}
				</template> -->
				<template #content>
					<div class="layout-profile">
						<Avatar :image="avatar" size="xlarge" class="avatar-xxl" shape="circle" alt="" />
						<h5>{{userCredentials.user.name}}</h5>
						<Tag v-for="role in userCredentials.roles" :key="role" class="mr-2">{{role}}</Tag>
					</div>
					<div class="p-fluid">
						<div class="field">
							<label>Avatar</label> (maxsize:10mb)
							<InputText @change="selectFile" type="file" accept="image/*"
								:class="{ 'p-invalid': avatarForm.errors.has('avatar') }" />
							<small class="p-error" v-show="avatarForm.errors.has('avatar')">
								{{ avatarForm.errors.get('avatar') }}
							</small>
						</div>
					</div>
					<Button label="Upload" @click="submitAvatar" :disabled="avatarForm.busy" :icon="(avatarForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-save'" />
				</template>
			</Card>
		</div>

		<div class="md:col-8 col-12">
			<Card>
				<template #title>
					{{title}}
				</template>
				<template #content>
					<TabView>
						<TabPanel header="Profile">
							<div class="p-fluid">
								<div class="field">
									<label>Name</label>
									<InputText v-model="profileForm.name"
										:class="{ 'p-invalid': profileForm.errors.has('name') }" />
									<small class="p-error" v-show="profileForm.errors.has('name')">
										{{ profileForm.errors.get('name') }}
									</small>
								</div>
								<div class="field">
									<label>Email</label>
									<InputText v-model="profileForm.email"
										:class="{ 'p-invalid': profileForm.errors.has('email') }" />
									<small class="p-error" v-show="profileForm.errors.has('email')">
										{{ profileForm.errors.get('email') }}
									</small>
								</div>
							</div>
							<Button label="Save" @click="submitProfile" :disabled="profileForm.busy" :icon="(profileForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-save'" />
						</TabPanel>
						<TabPanel header="Change Password">
							<div class="p-fluid">
								<div class="field">
									<label>New Password</label>
									<Password v-model="passwordForm.password" mediumRegex="^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})." toggleMask
										:class="{ 'p-invalid': passwordForm.errors.has('password') }">
										<template #footer>
											<Divider />
											<p class="mt-2">Suggestions</p>
											<ul class="pl-2 ml-2 mt-0" style="line-height: 1.5">
												<li>At least one lowercase</li>
												<li>At least one uppercase or one number</li>
												<li>Minimum 6 characters</li>
											</ul>
										</template>
									</Password>
									<small class="p-error" v-show="passwordForm.errors.has('password')">
										{{ passwordForm.errors.get('password') }}
									</small>
								</div>
								<div class="field">
									<label>Confirm Password</label>
									<Password v-model="passwordForm.password_confirmation" :feedback="false" toggleMask
										:class="{ 'p-invalid': passwordForm.errors.has('password_confirmation') }" />
									<small class="p-error" v-show="passwordForm.errors.has('password_confirmation')">
										{{ passwordForm.errors.get('password_confirmation') }}
									</small>
								</div>
							</div>
							<Button label="Save" @click="submitPassword" :disabled="passwordForm.busy" :icon="(passwordForm.busy) ? 'pi pi-spin pi-spinner' : 'pi pi-save'" />
						</TabPanel>
					</TabView>
				</template>
			</Card>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		userCredentials: {},
	},
	data() {
		return {
			title: 'User Profile',
			api: '/api/account',
			profileForm: new this.$FormAuth({
				// id: null,
				name: null,
				email: null,
			}),
			passwordForm: new this.$FormAuth({
				// id: null,
				password: null,
				password_confirmation: null,
			}),
			avatarForm: new this.$FormAuth({
				// id: null,
				avatar: null,
			}),
		}
	},
	computed: {
		avatar(){
			return (this.userCredentials.user.avatar) ? process.env.VUE_APP_API_BASE_URL + '/assets/user_avatar/' + this.userCredentials.user.avatar : process.env.VUE_APP_DEFAULT_AVATAR;
		},
	},
	methods: {
		loadUserData(){
			this.$Progress.start();
			this.$httpAuth.get(this.api)
			.then((response) => {
				this.$Progress.finish();
				this.profileForm.fill(response.data);
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},

		selectFile(e){
			const file = e.target.files[0];
			this.avatarForm.avatar = file;
		},

		submitProfile(){
			this.$Progress.start();
			this.profileForm.post(this.api)
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
				this.$e.emit('getUserCredentials');
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},
		submitPassword(){
			this.$Progress.start();
			this.passwordForm.post(this.api + '/password')
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},
		submitAvatar(){
			this.$Progress.start();
			this.avatarForm.post(this.api + '/avatar')
			.then((response) => {
				this.$Progress.finish();
				this.$toast.add({severity:'success', summary: 'Success', detail:response.data.message, life: 3000});
				this.$e.emit('getUserCredentials');
			})
			.catch((error) => {
				this.$Progress.fail();
				this.$toast.add({severity:'error', summary: 'Failed', detail:error.response.data.message, life: 3000});
			});
		},
	},
	beforeCreate(){
		
	},
	created(){
		this.$e.emit('updateTitle', this.title);
	},
	mounted() {
		this.loadUserData();
	},
}
</script>
